.blog-placeholder-svg {
  height: 100%;
}

@media screen and (min-width: 990px) {
  .grid--1-col-desktop .article-card .card__content {
    text-align: center;
  }
}

.blog__title {
  margin: 0;
}

.blog__posts.articles-wrapper {
  margin-bottom: 1rem;
}

@media screen and (min-width: 990px) {
  .blog__posts.articles-wrapper {
    margin-bottom: 0;
  }
}

.blog__posts.articles-wrapper .article {
  scroll-snap-align: start;
}

@media screen and (max-width: 749px) {
  .blog__post.article {
    width: calc(100% - 3rem - var(--grid-mobile-horizontal-spacing));
  }
}

.background-secondary .blog-placeholder__content {
  background-color: rgb(var(--color-background));
}

.blog__posts .card-wrapper {
  width: 100%;
}

.blog__button {
  margin-top: 3rem;
}

@media screen and (min-width: 750px) {
  .blog__button {
    margin-top: 5rem;
  }
}

/* check for flexbox gap in older Safari versions */
@supports not (inset: 10px) {
  @media screen and (min-width: 750px) {
    .blog__posts .article + .article {
      margin-left: var(--grid-desktop-horizontal-spacing);
    }
  }
}
